import type { IStyle } from '@fluentui/react';

export interface ILayoutStyles {
  pageContent: IStyle;
  title: IStyle;
  content: IStyle;
  mobileContent: IStyle;
}

export const layoutStyles: ILayoutStyles = {
  pageContent: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  title: {
    paddingTop: '8px',
    paddingLeft: '12px'
  },
  content: {
    paddingLeft: '32px'
  },
  mobileContent: {
    padding: '0 16px',
    display: 'flex',
    flexFlow: 'column'
  }
};
