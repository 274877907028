import {
  ConsoleTelemetrySinkFactory,
  OneDsTelemetrySinkFactory,
  initializeTelemetry
} from '@iamexperiences/ecos-telemetry';
import { v4 as uuidv4 } from 'uuid';
import { ITelemetryEnvironment, TelemetryEnvironment } from '../models';
import { settingsManager } from './settings';
import { ONE_DS_CONSOLE_QUERY_STRING } from './constants';
import { auth } from '../auth';

const isConsolePrintingEnabled = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isEnabled = queryParameters.get(ONE_DS_CONSOLE_QUERY_STRING);
  return isEnabled === 'true';
};

function createSinkFactory(endpointUrl?: string) {
  const sinkFactories = [
    OneDsTelemetrySinkFactory({
      instrumentKey: settingsManager.get('ecosTelemetryInstrumentationKey')!,
      endpointUrl,
      enableNetworkCallTracking: true,
      enableConsolePrinting: isConsolePrintingEnabled(),
      enablePublishTelemetry: process.env.NODE_ENV === 'production'
    })
  ];

  if (process.env.NODE_ENV === 'development') {
    sinkFactories.push(ConsoleTelemetrySinkFactory());
  }

  return sinkFactories;
}

export const createTelemetryObject = (environment: ITelemetryEnvironment) => {
  const telemetryObject = initializeTelemetry({
    sinkFactories: createSinkFactory(environment.endpoint),
    data: () => {
      return {
        namespace: 'AadMyAccess',
        env: environment.name,
        latestAuthEventType: auth.latestAuthEventType,
        userId: auth.user()?.objectId,
        tenantId: auth.user()?.tenantId,
        logId: uuidv4(),
        // This will get replaced at build time, if changing this, make sure to update the stage-build.yml
        buildVersion: '2.9.7'
      };
    }
  });

  return telemetryObject;
};
export const telemetryEnvironmentConfiguration = {
  [TelemetryEnvironment.WW]: {
    endpoint: settingsManager.get('defaultCollectorEndpoint')!,
    name: TelemetryEnvironment.WW
  },
  [TelemetryEnvironment.EU]: {
    endpoint: settingsManager.get('euCollectorEndpoint') ?? settingsManager.get('defaultCollectorEndpoint')!,
    name: TelemetryEnvironment.EU
  }
};

/**
 * Using the Europe kusto cluster as a default as a temporary workaround
 * until distinction with claims can be made
 *
 * Docs can be found here: https://msazure.visualstudio.com/One/_git/AD-IAM-Lib-EndUserExperiences?path=/projects/client-telemetry
 **/
export let telemetry = createTelemetryObject(telemetryEnvironmentConfiguration[TelemetryEnvironment.EU]);
