import { LOCATION_CHANGE } from 'react-router-redux';

import { IEntitlementAction } from '../../models';
import { IEntitlementState } from '../../models/IEntitlementState';
import { registry } from './myAccessRegistry';

export * from './accessReviews/getAccessReviewsFeatureFlags';
export * from './accessReviews/getDecisionHistory';
export * from './accessReviews/getDecisionsSummary';
export * from './accessReviews/getReviewerDisplayNames';
export * from './accessReviews/getSecondaryDecisions';
export * from './accessReviews/setReviewListPivot';
export * from './accessReviews/showAcceptRecommendations';
export * from './accessReviews/showBulkDecisionDialog';
export * from './accessReviews/showDecisionDetails';
export * from './accessReviews/showResetDecisions';
export * from './accessReviews/showReviewDecisionsDialog';
export * from './accessReviews/showReviewDecisionsFilter';
export * from './accessReviews/showReviewDetails';
export * from './accessReviews/showUserCentricPanel';
export * from './accessReviews/submitAllDecisions';
export * from './accessReviews/submitDecision';
export * from './approvalSubstitution/getApproverSubstitution';
export * from './approvalSubstitution/setApproverSubstitution';
export * from './count/getActiveGrantsCount';
export * from './count/getEntitlementsCount';
export * from './count/getExpiredGrantsCount';
export * from './count/getPendingApprovalGrantRequestsCount';
export * from './count/getPendingApprovalsCount';
export * from './decision/decision';
export * from './Diagnostics/getMember';
export * from './features';
export * from './filter/filterEntities';
export * from './get/getEntities';
export * from './get/getEntity';
export * from './get/getPeople';
export * from './get/getPhoto';
export * from './get/getRecommendations';
export * from './grantRequest/grantRequest';
export * from './grantRequest/updateGrantRequest';
export * from './grantRequest/validationErrors';
export * from './header';
export * from './obo/setGrantRequestTarget';
export * from './overview/getAccessPackageReviewCount';
export * from './overview/getGroupReviewCount';
export * from './overview/getOverviewApprovalCount';
export * from './overview/getOverviewExpiringGrantsCount';
export * from './policy/getPolicies';
export * from './ram/activateRamAccess';
export * from './ram/getPimPendingApprovalRequest';
export * from './ram/getVirtualMachineDetails';
export * from './ram/listRoleManagementPolicy';
export * from './ram/searchVirtualMachines';
export * from './ram/setErrorMessageBar';
export * from './ram/setRamErrorDialog';
export * from './ram/setVirtualMachinesSearchTerm';
export * from './ram/validateRoleActivation';
export * from './recordDecision/recordDecision';
export * from './refresh/refreshEntities';
export * from './search/searchAccessReviews';
export * from './search/searchCatalogs';
export * from './search/searchCompletedGrantRequests';
export * from './search/searchCompletedRaeRequests';
export * from './search/searchContext';
export * from './search/searchEntities';
export * from './search/searchEntitlements';
export * from './search/searchExpiredGrants';
export * from './search/searchGrantRequests';
export * from './search/searchGrantRequests';
export * from './search/searchPendingApprovalGrantRequests';
export * from './search/searchPendingRaeRequests';
export * from './search/searchReviewDecisions';
export * from './search/searchValidGrants';
export * from './search/updateFilterItems';
export * from './set/setPartialGrantRequest';
export * from './show/showAddGrantRequest';
export * from './show/showBulkActionDialog';
export * from './show/showCompletedGrantRequestDetails';
export * from './show/showCompletedRaeRequestDetails';
export * from './show/showConfirmDialog';
export * from './show/showCopyLink';
export * from './show/showCopyNotification';
export * from './show/showGrantRequestDetails';
export * from './show/showGrantRequestFilter';
export * from './show/showMessageBar';
export * from './show/showMoreGrantRequestDetails';
export * from './show/showPendingApprovalGrantRequestDetails';
export * from './show/showPendingRaeRequestDetails';
export * from './show/showSearchBox';
export * from './show/showSearchFiltersIcon';
export * from './show/showValidGrantFilter';
export * from './sort/setSortedColumn';
export * from './sort/sortEntities';
export * from './telemetry';
export * from './tenant/afterLogin';
export * from './tenant/findTenantByDomainName';
export * from './tenant/getTenant';
export * from './verifiableCredentialPresentation/delete';

const locationChange = (
  state: IEntitlementState,
  _action: IEntitlementAction<{ pathname: string }>
): IEntitlementState => {
  return {
    ...state
  };
};
registry.add(LOCATION_CHANGE, locationChange);

export const entitlementReducers = registry.getReducer();

export const entitlementEpics = registry.getEpics();
