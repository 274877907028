import { Heading, Stack, useViewport, ViewportType } from '@amx/ux-twoui';
import { classNamesFunction } from '@fluentui/react';
import { useLocalization } from '@microsoft/portal-app/lib/shared/localization';
import React, { useEffect, useState } from 'react';
import type { RouteProps } from 'react-router-dom';

import { ILayoutStyles, layoutStyles } from '../../Application/layout/Layout.styles';
import { useFeatures } from '../../hooks';
import { isInternalUser, LocaleKeys } from '../../shared';
import { MOBILE_BREAK_POINT, NAVIGATION_LINKS } from '../../shared/constants';
import { LeftNav } from '../left-nav/LeftNav';

const getClassNames = classNamesFunction<Record<string, never>, ILayoutStyles>();
const classNames = getClassNames(layoutStyles);
export const Layout: React.FC<RouteProps> = ({ location, children }) => {
  const [t] = useLocalization(['MyAccess']);
  const features = useFeatures();

  const [title, setTitle] = useState<string | null>();
  const [selectedNavLinkKey, setSelectedNavLinkKey] = useState<string | undefined>(NAVIGATION_LINKS.overview.key);

  useEffect(() => {
    const path = location?.hash ?? '#/';
    const mainPath = `/${path.split('/')[1]}`;

    switch (mainPath) {
      case '/':
        setSelectedNavLinkKey( NAVIGATION_LINKS.overview.key);
        setTitle(t(LocaleKeys.overviewUpper));
        break;
      case NAVIGATION_LINKS.accessPackages.link:
        setSelectedNavLinkKey(NAVIGATION_LINKS.accessPackages.key);
        setTitle(t(LocaleKeys.accessPackage, { context: 'plural' }));
        break;
      case NAVIGATION_LINKS.requestHistory.link:
        setSelectedNavLinkKey(NAVIGATION_LINKS.requestHistory.key);
        setTitle(t(LocaleKeys.requestHistory));
        break;
      case NAVIGATION_LINKS.requestApprovals.link:
        setSelectedNavLinkKey(NAVIGATION_LINKS.requestApprovals.key);
        setTitle(t(LocaleKeys.approvalUpper, { context: 'plural' }));
        break;
      case NAVIGATION_LINKS.accessReviews.link:
        setSelectedNavLinkKey(NAVIGATION_LINKS.accessReviews.key);
        setTitle(t(LocaleKeys.accessReviews));
        break;
      case NAVIGATION_LINKS.overview.link:
        setSelectedNavLinkKey(NAVIGATION_LINKS.overview.key);
        setTitle(t(LocaleKeys.overviewUpper));
        break;
      case NAVIGATION_LINKS.catalogs.link:
        if (features.enableCatalogView) {
          setSelectedNavLinkKey(NAVIGATION_LINKS.catalogs.key);
          setTitle(t(LocaleKeys.catalogUpper, { context: 'plural' }));
        }
        break;
      case NAVIGATION_LINKS.ram.link:
        if (features.enableRam && isInternalUser()) {
          setSelectedNavLinkKey(NAVIGATION_LINKS.ram.key);
          setTitle(t(LocaleKeys.cloudInfrastructure));
        }
        break;
      default:
        setSelectedNavLinkKey(undefined);
    }
  }, [
    features.enableCatalogView,
    features.enableRam,
    location?.hash,
    location?.pathname,
    selectedNavLinkKey,
    t
  ]);

  const viewportType = useViewport();

  if (viewportType === MOBILE_BREAK_POINT) {
    return (
      <Stack horizontal={false} width={'100%'}>
        <Stack horizontal={true}>
          {selectedNavLinkKey ? (
            <LeftNav selectedNavLinkKey={selectedNavLinkKey} setLeftNavSelectedItem={setSelectedNavLinkKey} />
          ) : null}
          {title && (
            <div className={classNames.title}>
              <Heading tag="h1">{title}</Heading>
            </div>
          )}
        </Stack>
        <LayoutContent viewportType={viewportType}>{children}</LayoutContent>
      </Stack>
    );
  } else {
    return (
      <>
        {selectedNavLinkKey ? (
          <LeftNav selectedNavLinkKey={selectedNavLinkKey} setLeftNavSelectedItem={setSelectedNavLinkKey} />
        ) : null}
        <LayoutContent viewportType={viewportType}>{children}</LayoutContent>
      </>
    );
  }
};

const LayoutContent: React.FC<{
  viewportType: ViewportType;
  children?: ((props: { [K: string]: string | undefined }) => React.ReactNode) | React.ReactNode | undefined;
}> = ({ viewportType, children }) => {
  return (
    <div className={classNames.pageContent} id={'main-content'} tabIndex={-1}>
      <div className={viewportType === MOBILE_BREAK_POINT ? classNames.mobileContent : classNames.content}>
        {children}
      </div>
    </div>
  );
};
