/**
 * Actions have a type that where the name will show up in logs.
 */
export enum EntitlementActions {
  emitTelemetry = 'telemetry/emit',

  // control panel show/dismiss
  showAddGrantRequest = 'entitlements/show-grant-request',
  setPartialGrantRequest = 'entitlements/set-partial-grant-request',
  showGrantRequestDetails = 'entitlements/show-grant-request-details',
  showMoreGrantRequestDetails = 'entitlements/show-more-grant-request-details',
  showPendingApprovalGrantRequestDetails = 'GrantRequests/pending/show-details',
  showCompletedGrantRequestDetails = 'GrantRequests/completed/show-details',
  showCopyNotification = 'grant/share-link',
  showPendingRaeRequestDetails = 'RaeRequests/show-details',
  showCompletedRaeRequestDetails = 'RaeRequests/completed/show-details',
  showGrantFilter = 'grants/show-filter',
  showGrantRequestFilter = 'grantRequests/show-filter',
  showBulkActionDialog = 'bulkActionDialog/show',
  showCopyLink = 'copylink/show',
  showConfirmDialog = 'confirm/dialog/show',
  showMessageBar = 'message-bar/show',
  // post grant request
  addGrantRequest = 'grantRequests/add',
  addGrantRequestSucceeded = 'grantRequests/add/success',
  addGrantRequestFailed = 'grantRequests/add/fail',
  // patch grant request
  updateGrantRequest = 'grantRequests/update',
  updateGrantRequestSucceeded = 'grantRequests/update/success',
  updateGrantRequestFailed = 'grantRequests/update/fail',
  refreshEntities = 'entities/refresh',
  findTenantByDomainName = 'tenants/find/domainName',
  findTenantByDomainNameSucceeded = 'tenants/find/domainName/success',
  findTenantByDomainNameFailed = 'tenants/find/domainName/fail',
  getTenant = 'tenant/get',
  getTenantSucceeded = 'tenant/get/success',
  getTenantFailed = 'tenant/get/fail',
  // Post decision
  postDecision = 'decision/post',
  postDecisionSucceeded = 'decision/post/success',
  postDecisionFailed = 'decision/post/fail',
  // Patch decision
  patchDecision = 'recordDecision/patch',
  patchDecisionSucceeded = 'recordDecision/patch/success',
  patchDecisionFailed = 'recordDecision/patch/fail',

  // Search related actions
  setSearchContext = 'search/context/set',
  updateFilterItems = 'search/update/filteritems',
  searchValidGrantsOnServer = 'grants/valid/search/server',
  searchExpiredGrantsOnServer = 'grants/expired/search/server',
  searchGrantRequestsOnServer = 'grantRequests/search/server',
  searchPendingApprovalGrantRequestsOnServer = 'grantRequestApprovals/pending/search/server',
  searchCompletedGrantRequestsOnServer = 'grantRequestApprovals/completed/search/server',
  searchEntitlementsOnServer = 'entitlements/search/server',
  searchPendingRaeRequestsOnServer = 'approvals/pending/search/server',
  searchCompletedRaeRequestsOnServer = 'approvals/completed/search/server',
  searchAccessReviewsOnServer = 'accessreviews/groupsapps/search/server',
  searchReviewDecisionsOnServer = 'reviewdecisions/search/server',
  searchCatalogsOnServer = 'catalogs/search/server',
  showSearchFiltersIcon = 'search/filterIcons/show',
  showSearchBox = 'search/box/show',

  // sort
  setSortedByColumn = 'sort/set-column',
  sortEntities = 'entities/sort',
  sortFilteredEntities = 'entities/filtered/sort',

  // get entities
  getEntities = 'entities/get',
  getEntitiesSucceeded = 'entities/get/success',
  getEntitiesFailed = 'entities/get/fail',

  // get entity
  getEntity = 'entity/get',
  getEntitySucceeded = 'entity/get/success',
  getEntityFailed = 'entity/get/fail',

  // get policy
  getPolicies = 'grant/policies/get',
  getPoliciesSucceeded = 'grant/policies/get/success',
  getPoliciesFailed = 'grant/policies/get/fail',

  getActiveGrantsCount = 'validGrants/count',
  getActiveGrantsCountSucceeded = 'validGrants/count/success',
  getActiveGrantsCountFailed = 'validGrants/count/fail',

  getExpiredGrantsCount = 'expiredGrants/count',
  getExpiredGrantsCountSucceeded = 'expiredGrants/count/success',
  getExpiredGrantsCountFailed = 'expiredGrants/count/fail',

  getEntitlementsCount = 'entitlements/count',
  getEntitlementsCountSucceeded = 'entitlements/count/success',
  getEntitlementsCountFailed = 'entitlements/count/fail',

  // search
  searchEntitiesOnServerSucceeded = 'entities/server-search/success',
  searchEntitiesOnServerFailed = 'entities/server-search/fail',
  searchForMore = 'entities/search/more',

  // filter
  filterEntities = 'entities/filter',
  filterEntitiesOnServer = 'entities/server-filter',
  filterEntitiesOnServerSucceeded = 'entities/server-filter/success',
  filterEntitiesOnServerFailed = 'entities/server-filter/fail',

  // clear validation errors
  clearValidationErrors = 'validationErrors/clear',

  // diagnostics related
  getMember = 'member/get',
  getMemberSucceeded = 'member/get/success',
  getMemberFailed = 'member/get/fail',

  // access reviews
  setReviewListPivot = 'accessreviews/pivot',
  showReviewDetails = 'accessreviews/details',
  showDecisionDetails = 'accessreviews/decision-details',
  showUserCentricPanel = 'accessreviews/show-user-centric',
  showReviewDecisionsFilter = 'accessreviews/decisions/show-filter',
  showReviewDecisionsDialog = 'accessreviews/decisions/show-dialog',
  showBulkDecisionDialog = 'accessreviews/bulk-decision',
  showResetDecisions = 'accessreviews/reset-decisions',
  showAcceptRecommendations = 'accessreviews/accept-recommendations',
  submitDecision = 'accessreviews/submit-decision',
  submitDecisionSucceeded = 'accessreviews/submit-decision/success',
  submitDecisionFailed = 'accessreviews/submit-decision/fail',
  submitAllDecisions = 'accessreviews/submit-all-decisions',
  submitAllDecisionsSucceeded = 'accessreviews/submit-all-decisions/success',
  submitAllDecisionsFailed = 'accessreviews/submit-all-decisions/fail',
  getCurrentReview = 'accessreviews/decisions/current-review',
  getDecisionsCriteria = 'accessreviews/decisions-criteria',
  getDecisionsSummary = 'accessreviews/decisions-summary',
  getDecisionsSummarySucceeded = 'accessreviews/decisions-summary/success',
  getDecisionsSummaryFailed = 'accessreviews/decisions-summary/failed',
  getDecisionHistory = 'accessreviews/decision-history',
  getDecisionHistorySucceeded = 'accessreviews/decision-history/success',
  getDecisionHistoryFailed = 'accessreviews/decision-history/failed',
  getAccessReviewsFeatureFlags = 'accessreviews/accessReviewsFeatureFlags/get',
  getAccessReviewsFeatureFlagsSucceeded = 'accessreviews/accessReviewsFeatureFlags/success',
  getAccessReviewsFeatureFlagsFailed = 'accessreviews/accessReviewsFeatureFlags/failed',
  getReviewerDisplayNames = 'accessreviews/reviewer/display-names',
  getReviewerDisplayNamesSucceeded = 'accessreviews/reviewer/display-names/success',
  getReviewerDisplayNamesFailed = 'accessreviews/reviewer/display-names/failed',
  getSecondaryDecisions = 'accessreviews/reviewer/secondary-decisions',
  getSecondaryDecisionsSucceeded = 'accessreviews/reviewer/secondary-decisions/success',
  getSecondaryDecisionsFailed = 'accessreviews/reviewer/secondary-decisions/failed',

  // get pending approvals' count
  getPendingApprovalsCount = 'approvals/count',
  getPendingApprovalsCountSucceeded = 'approvals/count/success',
  getPendingApprovalsCountFailed = 'approvals/count/fail',

  // get pending approval grant requests count
  getPendingApprovalGrantRequestsCount = 'request-approval/count',
  getPendingApprovalGrantRequestsCountSucceeded = 'request-approval/count/success',
  getPendingApprovalGrantRequestsCountFailed = 'request-approval/count/fail',
  getOrganizations = 'header/get-organizations',
  getNavBarData = 'header/get-navbar-data',
  getEnabledApps = 'header/get-enabled-apps',
  getTenantBranding = 'header/get-tenant-branding',
  getCurrentOrganization = 'header/get-current-organization',
  getUserImageUrl = 'header/get-user-image-url',
  submitSignOut = 'account/signOut/submit',
  submitSignOutSuccess = 'account/signOut/success',
  submitSignOutFailed = 'account/signOut/failed',

  // Verifiable Credential's Presentation Status requests
  deleteVerifiableCredentialPresentationStatus = 'grantRequests/verifiable-credential-presentation/delete',
  deleteVerifiableCredentialPresentationStatusSuccess = 'grantRequests/verifiable-credential-presentation/delete/success',
  deleteVerifiableCredentialPresentationStatusFailed = 'grantRequests/verifiable-credential-presentation/delete/failed',

  // Overview data
  getOverviewExpiringGrants = 'overviewExpiringGrants/get',
  getOverviewExpiringGrantsSucceeded = 'overviewExpiringGrants/get/success',
  getOverviewExpiringGrantsFailed = 'overviewExpiringGrants/get/failed',

  getOverviewApproval = 'overviewPendingApproval/get',
  getOverviewApprovalSucceeded = 'overviewPendingApproval/get/success',
  getOverviewApprovalFailed = 'overviewPendingApproval/get/failed',

  getGroupReviewCount = 'overviewGroupAccessReview/get',
  getGroupReviewCountSucceeded = 'overviewGroupAccessReview/get/success',
  getGroupReviewCountFailed = 'overviewGroupAccessReview/get/failed',

  getAccessPackageReviewCount = 'overviewAccessPackageAccessReview/get',
  getAccessPackageReviewCountSucceeded = 'overviewAccessPackageAccessReview/get/success',
  getAccessPackageReviewCountFailed = 'overviewAccessPackageAccessReview/get/failed',

  getAdminOptInFeatures = 'settings/myFeatures/get',
  getAdminOptInFeaturesSucceeded = 'settings/myFeatures/get/success',
  getAdminOptInFeaturesFailed = 'settings/myFeatures/get/failed',
  refreshAdminOptInFeatures = 'settings/myFeatures/refresh',
  refreshAdminOptInFeaturesSucceeded = 'settings/myFeatures/refresh/success',
  refreshAdminOptInFeaturesFailed = 'settings/myFeatures/refresh/failed',
  getCachedFeatures = 'features/cached/get',

  // OBO data
  setGrantRequestTargetType = 'grantRequests/type',

  // Direct Reports
  getPeople = 'people/get',
  getPeopleSucceeded = 'people/get/success',
  getPeopleFailed = 'people/get/failed',

  // Recommendations
  getRecommendations = 'recommendations/get',
  getRecommendationsSuccess = 'recommendations/get/success',
  getRecommendationsFailed = 'recommendations/get/failed',

  // Photos
  getPhoto = 'photo/get',
  getPhotoSucceeded = 'photo/get/success',
  getPhotoFailed = 'photo/get/failed',

  // RAM access activate
  activateRamAccess = 'ram/access/activate',
  activateRamAccessFailed = 'ram/access/activate/failed',
  deactivateRamAccess = 'ram/access/deactivate',
  deactivateRamAccessFailed = 'ram/access/deactivate/failed',
  deactivateRamAccessSuccess = 'ram/access/deactivate/success',
  setActivationStatus = 'ram/access/statusUpdateDialogToggle/set',
  listRoleManagementPolicy = 'ram/access/roleManagementPolicy/list',
  listRoleManagementPolicySuccess = 'ram/access/roleManagementPolicy/list/success',
  listRoleManagementPolicyFailed = 'ram/access/roleManagementPolicy/list/failed',
  clearQuickActivationDialogValue = 'ram/access/quickActivationDialogValue/clear',
  validateRoleActivation = 'ram/access/activate/validate',
  validateRoleActivationFailed = 'ram/access/activate/validate/failed',
  validateRoleActivationSucceeded = 'ram/access/activate/validate/success',
  setErrorMessageBar = 'ram/error/messageBar/set',
  setRamErrorDialog = 'ram/error/dialog/set',
  getVirtualMachineDetails = 'ram/access/virtualMachine/details/get',
  setSelectedVirtualMachineDetails = 'ram/access/virtualMachine/details/set',
  getVirtualMachineDetailsFailed = 'ram/access/virtualMachine/details/get/failed',
  searchVirtualMachinesOnServer = 'ram/virtualMachines/search/server',
  getPimPendingApprovalRequest = 'ram/access/pimPendingApprovalRequest/get',
  getPimPendingApprovalRequestSucceeded = 'ram/access/pimPendingApprovalRequest/get/success',
  setVirtualMachinesSearchTerm = 'ram/virtualMachines/search/term/set',

  // Approver Substitution
  getApproverSubstitution = 'approverSubstitution/get',
  getApproverSubstitutionSuccess = 'approverSubstitution/get/success',
  getApproverSubstitutionFailed = 'approverSubstitution/get/failed',

  setApproverSubstitution = 'approverSubstitution/set',
  setApproverSubstitutionSuccess = 'approverSubstitution/set/success',
  setApproverSubstitutionFailed = 'approverSubstitution/set/failed',
}

export interface EntitiesAction {
  entityType: string;
}
